<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { TextInput } from '@goodvibes/kudoslink';

const props = defineProps({
    topic: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: false,
    },
    errors: {
        type: Array,
        required: false,
    },
});

const emit = defineEmits(['update:topic', 'update:description']);
</script>

<template>
    <div v-bind="$attrs" class="space-y-6">
        <div>
            <TextInput
                inputClass="input--small font-sans font-normal text-[#3F4A55] w-full bg-[#F8F9FF] rounded-xl border-[#DBDBF9]"
                :modelValue="topic"
                @update:modelValue="emit('update:topic', $event)"
                :errors="errors?.topic ? [errors.topic] : undefined"
                placeholder="What would you like to ask?"
            />
        </div>
        <div>
            <TextInput
                inputClass="input--small font-sans font-normal text-[#3F4A55] w-full bg-[#F8F9FF] rounded-xl border-[#DBDBF9]"
                :modelValue="description"
                @update:modelValue="emit('update:description', $event)"
                :errors="errors?.description ? [errors.description] : undefined"
                placeholder="Description (optional)"
            />
        </div>
    </div>
</template>
