<!-- 
    Component for displaying the main area of tv view 
    and live preview in moderator view.
-->
<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import {
    MultipleChoiceModuleResponse,
    ScaleModuleResponse,
    TextInput,
    ScaleModule,
    KudoCard,
} from '@goodvibes/kudoslink';
import { HandThumbUpIcon } from '@heroicons/vue/24/outline';
import { QuestionCard, WordCloud } from '../Components';
import { useAttrs, computed } from 'vue';
import { PollTypes, rankAnswers } from '@/shared';
import { Question, Poll, OpenText } from '@/models';
import { isDarkTheme, themes } from './ThemePicker.vue';
import { vInfiniteScroll } from '@vueuse/components';

const props = defineProps({
    meeting: {
        type: Object,
        required: true,
    },
    activePoll: {
        type: Object,
        required: true,
    },
    responses: {
        type: Array,
        required: true,
    },
    wordCloudTheme: {
        type: String,
        required: false,
        validator(val) {
            return ['black', 'white'].includes(val);
        },
    },
    accentColor: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['loadMore']);

const attrs = useAttrs();

const wordCloudThemeToBgMap = {
    default: 'bg-white/10',
    carbon: 'bg-white/10',
    'red office': 'bg-white/10',
};

/** TV View theme */
const theme = computed(() => {
    return props.meeting.theme ? props.meeting.theme : themes[0].value;
});

const wordCloudBackground = computed(() => {
    return wordCloudThemeToBgMap[theme.value] ?? isDarkTheme(theme.value) ? 'bg-white/20' : 'bg-white/100';
});

const wcTheme = computed(() => {
    if (props.wordCloudTheme) return props.wordCloudTheme;
    return isDarkTheme(theme.value) ? 'black' : 'white';
});

const wordCloudThemeLevelStyles = {
    ripples: { 3: 'bg-[#2B333B]/40' },
    carbon: { 3: 'bg-[#2B333B]/75', 4: 'bg-[#2B333B]' },
    'blue office': { 3: 'bg-[#2B333B]/25' },
    'sand wall': { 3: 'bg-[#2B333B]/25' },
    'wooden architecture': { 3: 'bg-[#2B333B]/25' },
    waves: { 3: 'bg-[#2B333B]/25' },
    punk: { 3: 'bg-[#2B333B]/25' },
    layers: { 3: 'bg-[#2B333B]/40' },
};

function wordCloudStyleWordLevel(level) {
    return wordCloudThemeLevelStyles[theme.value]?.[level];
}

function onLoadMore() {
    emit('loadMore');
}
</script>

<template>
    <div
        v-if="activePoll.type === PollTypes.QA"
        class="space-y-4 max-h-[44rem] overflow-y-scroll"
        v-infinite-scroll="[onLoadMore, { distance: 5 }]"
    >
        <TransitionGroup name="list">
            <QuestionCard
                v-for="question in Question.sort(responses, 'upvotes', 'desc', q =>
                    Poll.isResponseHighlighted(q, activePoll),
                )"
                :key="question.id"
                class="w-full transition-all ease-in-out duration-300"
                :class="{
                    'opacity-50': activePoll.highlightedResponseId && !Poll.isResponseHighlighted(question, activePoll),
                }"
            >
                <template #author>
                    <div class="text-lg font-semibold text-[#8C96A1]" v-text="question.name"></div>
                </template>
                <p class="text-4xl leading-10" v-text="question.message"></p>

                <template #footer>
                    <div class="flex items-center justify-between gap-x-4 text-xs">
                        <div class="grow"></div>
                        <Transition name="fade">
                            <div class="text-[#8C96A1]">
                                <span class="mr-1 inline-block align-middle text-2xl">{{
                                    question.upvotes.length
                                }}</span>
                                <HandThumbUpIcon class="h-6 align-middle inline-block" />
                            </div>
                        </Transition>
                    </div>
                </template>
            </QuestionCard>
        </TransitionGroup>
    </div>

    <div
        v-if="activePoll.type === PollTypes.OPEN_TEXT"
        class="space-y-4 max-h-[34rem] overflow-y-scroll"
        v-infinite-scroll="[onLoadMore, { distance: 5 }]"
    >
        <TransitionGroup name="list">
            <QuestionCard
                v-for="openText in OpenText.sort(responses, 'createdAt', 'desc')"
                :key="openText.id"
                class="w-full transition-all ease-in-out duration-300"
            >
                <template #author>
                    <div
                        v-if="activePoll.settings.showNames"
                        class="text-lg font-semibold text-[#8C96A1]"
                        v-text="openText.name"
                    ></div>
                </template>
                <p class="text-4xl leading-10" v-text="openText.message"></p>
            </QuestionCard>
        </TransitionGroup>
    </div>

    <template v-else-if="activePoll.showResults">
        <div v-if="[PollTypes.MULTIPLE_CHOICE].includes(activePoll.type)" class="grow">
            <MultipleChoiceModuleResponse
                :answers="responses.map(response => response.answers)"
                :choices="activePoll.choices.map(choice => choice.option)"
                resultsListClasses="max-h-none"
                v-bind="attrs"
            />
        </div>

        <div v-if="[PollTypes.RANKING].includes(activePoll.type)" class="grow">
            <MultipleChoiceModuleResponse
                :answers="responses.map(response => rankAnswers(response.answers))"
                :choices="activePoll.choices.map(choice => choice.option)"
                resultsListClasses="max-h-none"
                v-bind="attrs"
            >
                <template #votes="{ votes }">
                    <span>
                        <!-- Do not show num of votes -->
                    </span>
                </template>
            </MultipleChoiceModuleResponse>
        </div>

        <div v-if="activePoll.type === PollTypes.RATING" class="grow">
            <KudoCard>
                <ScaleModuleResponse
                    class="pt-12"
                    :answers="responses.map(response => response.rating)"
                    :answered="activePoll.responsesCount"
                    :scaleType="activePoll.settings.scaleUnit"
                    :minScale="+activePoll.settings.min"
                    :maxScale="+activePoll.settings.max"
                    :textLeft="activePoll.settings.minLabel"
                    :textRight="activePoll.settings.maxLabel"
                    v-bind="attrs"
                />
            </KudoCard>
        </div>

        <div
            v-if="activePoll.type === PollTypes.WORD_CLOUD"
            class="w-full h-full grow-0 p-12 rounded-3xl"
            :class="wordCloudBackground"
        >
            <WordCloud :wordClouds="responses" :theme="wcTheme" :applyWordClasses="wordCloudStyleWordLevel" />
        </div>
    </template>
    <template v-else>
        <!-- Show non interactive polls -->
        <template v-if="[PollTypes.MULTIPLE_CHOICE, PollTypes.RANKING].includes(activePoll.type)">
            <div class="border-none px-7 pt-7 pb-8 bg-white rounded-2xl">
                <div
                    v-for="(choice, index) in activePoll.choices"
                    :key="index"
                    class="border gap-x-4 mt-2 border-[#DBDBF9] bg-[#F8F9FF] rounded-[14px] w-full inline-flex items-center"
                >
                    <img
                        v-if="choice.image"
                        :src="choice.image"
                        class="rounded-l-[14px] object-cover h-24 aspect-square"
                        :alt="`${choice.option} image`"
                    />
                    <div class="my-4 mx-12 flex gap-x-4 items-center">
                        <div class="rounded-full w-3 h-3 bg-black"></div>
                        <span class="align-middle mr-8 text-3xl">{{ choice.option }}</span>
                    </div>
                </div>
                <div class="flex items-center gap-5 w-full" v-if="activePoll.settings?.customAnswers">
                    <label
                        class="border gap-x-4 mt-2 border-[#DBDBF9] bg-[#F8F9FF] rounded-[14px] py-4 px-12 w-fit inline-flex items-center"
                    >
                        <div class="rounded-full w-3 h-3 bg-black"></div>
                        <span class="align-middle mr-8">Other:</span>
                    </label>
                    <div class="w-full">
                        <TextInput
                            inputClass="mt-6 px-0 py-1 focus:outline-none border-t-0 border-l-0 border-r-0 border-b-[1px] rounded-none border-[#BBC4CD] focus:border-b-[#6212E5] hover:border-b-[#6212E5] disabled:bg-white"
                            disabled
                            placeholder="Your answer"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="activePoll.type === PollTypes.RATING">
            <ScaleModule
                :scaleType="activePoll.settings.scaleUnit"
                :minScale="+activePoll.settings.min"
                :maxScale="+activePoll.settings.max"
                :textLeft="activePoll.settings.minLabel"
                :textRight="activePoll.settings.maxLabel"
                :disabled="true"
            />
        </template>
    </template>
</template>
