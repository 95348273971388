<script setup>
import VueWordCloud from 'vuewordcloud';
import groupBy from 'lodash.groupby';
import { computed } from 'vue';
import { PopperTooltip } from '@goodvibes/kudoslink';
import { twMerge } from 'tailwind-merge';

/**
 * @type {{ WordClouds: Array, applyWordClasses?: (level: Number) => string, theme: "white" | "black" }}
 */
const props = defineProps({
    wordClouds: {
        type: Array,
        required: true,
    },
    /**
     * Consument (which has more context about where the WC is being used) can override the styling for each word level
     */
    applyWordClasses: {
        type: Function,
        required: false,
        default: () => '',
    },
    /**
     * Theme of the wordcloud.
     * Either: "white" or "black"
     */
    theme: {
        type: String,
        default: 'white',
        validator(value) {
            return ['white', 'black'].includes(value);
        },
    },
});

const wordLevelMap = computed(() => {
    const wordMap = new Map();

    const groupedWords = Object.entries(groupBy(props.wordClouds, 'wordCount'))
        .sort(([wordCount1], [wordCount2]) => +wordCount2 - +wordCount1)
        .map(([wordCount, wordClouds]) => wordClouds.map(wc => wc.word));

    if (!groupedWords.length) return wordMap;

    groupedWords.forEach(group => {
        let level;

        if (group.length <= 1) level = 10;
        else if (group.length <= 2) level = 9;
        else if (group.length <= 3) level = 8;
        else if (group.length <= 4) level = 7;
        else if (group.length <= 5) level = 6;
        else if (group.length <= 6) level = 5;
        else if (group.length <= 7) level = 4;
        else if (group.length <= 8) level = 3;
        else if (group.length <= 9) level = 2;
        else level = 1;

        group.forEach(word => wordMap.set(word, level));
    });

    return wordMap;
});

function getLevelClasses(word) {
    const level = wordLevelMap.value.get(word) ?? 1;
    return twMerge(themes[props.theme](level), props.applyWordClasses(word));
}

const themes = {
    black: function (level) {
        const defaultClasses = `rounded-[1.25rem] font-['Red_Hat_Display'] scale-[60%]`;

        if (level === 1) return `${defaultClasses} text-white text-opacity-75`;
        if (level === 2) return `${defaultClasses} text-white`;
        if (level === 3) return `${defaultClasses} bg-[#2B333B80]/35 text-white p-2`;
        if (level === 4) return `${defaultClasses} bg-[#2B333B80]/50 text-white p-3`;
        if (level === 5) return `${defaultClasses} bg-white/60 text-[#2B333B] p-4`;
        if (level === 6) return `${defaultClasses} bg-white/70 text-[#2B333B] p-5`;
        if (level === 7) return `${defaultClasses} bg-white/80 text-[#2B333B] p-6`;
        if (level === 8) return `${defaultClasses} text-[#2B333B] bg-white/90 p-7`;

        return `${defaultClasses} text-[#2B333B] bg-white p-9`;
    },
    white: function (level) {
        const defaultClasses = `rounded-[1.25rem] font-['Red_Hat_Display'] scale-[60%]`;

        if (level === 1) return `${defaultClasses} text-[#2B333B] text-opacity-75`;
        if (level === 2) return `${defaultClasses} text-[#2B333B]`;
        if (level === 3) return `${defaultClasses} bg-[#2B333B]/25 text-white p-2`;
        if (level === 4) return `${defaultClasses} bg-[#2B333B]/50 text-white p-3`;
        if (level === 5) return `${defaultClasses} bg-[#2B333B]/60 text-white p-4`;
        if (level === 6) return `${defaultClasses} bg-[#2B333B]/70 text-white p-5`;
        if (level === 7) return `${defaultClasses} bg-[#2B333B]/80 text-white p-6`;
        if (level === 8) return `${defaultClasses} bg-[#2B333B]/90 text-white p-7`;

        return `${defaultClasses} text-white bg-[#2B333B] p-9`;
    },
};

function truncateString(str, length) {
    if (str.length <= length) {
        return str;
    }
    return str.slice(0, length) + '...';
}
</script>

<template>
    <VueWordCloud
        :spacing="1.5"
        :fontSizeRatio="2.2"
        fontWeight="700"
        :words="
            wordClouds
                .sort(({ wordCount: wc1 }, { wordCount: wc2 }) => wc2 - wc1)
                .map(({ wordCount, word }) => [word, wordCount])
        "
    >
        <template #default="{ text, word, weight, wordEntry }">
            <PopperTooltip v-if="text.length > 25">
                <div :class="getLevelClasses(text)">{{ truncateString(text, 25) }}</div>
                <template #popper>
                    <div>{{ text }}</div>
                </template>
            </PopperTooltip>
            <div v-else :class="getLevelClasses(text)">{{ text }}</div>
        </template>
    </VueWordCloud>
</template>
