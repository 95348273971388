<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { TextInput, Icons, PopperTooltip, CheckboxInput } from '@goodvibes/kudoslink';

const props = defineProps({
    topic: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: false,
    },
    showNames: {
        type: Boolean,
        required: true,
    },
    errors: {
        type: Array,
        required: false,
    },
});

const emit = defineEmits(['update:topic', 'update:description', 'update:showNames']);
</script>

<template>
    <div v-bind="$attrs" class="space-y-6">
        <div>
            <TextInput
                inputClass="input--small font-sans font-normal text-[#3F4A55] w-full bg-[#F8F9FF] rounded-xl border-[#DBDBF9]"
                :modelValue="topic"
                @update:modelValue="emit('update:topic', $event)"
                :errors="errors?.topic ? [errors.topic] : undefined"
                placeholder="What would you like to ask?"
            />
        </div>
        <div>
            <TextInput
                inputClass="input--small font-sans font-normal text-[#3F4A55] w-full bg-[#F8F9FF] rounded-xl border-[#DBDBF9]"
                :modelValue="description"
                @update:modelValue="emit('update:description', $event)"
                :errors="errors?.description ? [errors.description] : undefined"
                placeholder="Description (optional)"
            />
        </div>
    </div>
    <label>
        <div class="flex items-center input-label mt-5">
            <span>Show respondent names</span>
            <PopperTooltip :triggers="['hover']">
                <Icons.InfoIcon class="cursor-pointer h-4 w-4 fill-[#BBC4CD] ml-1" aria-hidden="true"></Icons.InfoIcon>
                <template #popper>
                    <p>Allow participants the option to share their names</p>
                </template>
            </PopperTooltip>
            <CheckboxInput
                :modelValue="showNames"
                @update:modelValue="emit('update:showNames', $event)"
                :value="showNames"
                class="shrink-0 ml-5"
            />
        </div>
    </label>
</template>
