<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { TextInput, TextAreaInput, CharacterCounter } from '@goodvibes/kudoslink';
import { computed, ref, useAttrs } from 'vue';

const props = defineProps({
    name: {
        type: [String, Boolean],
    },
    message: {
        type: String,
    },
    characterLimit: {
        type: Number,
        required: false,
    },
    showNameField: {
        type: Boolean,
        required: false,
        default: true,
    },
    errors: {
        type: Object,
        required: false,
    },
});

const withNameInput = computed(() => props.name !== false);

const { messageInputAttrs, nameInputAttrs, ...rootAttrs } = useAttrs();

const messageFocused = ref(false);
const nameFocused = ref(false);

const revealNameInput = computed(() => {
    return props.message || messageFocused.value || nameFocused.value || props.errors?.message;
});

const emit = defineEmits(['update:name', 'update:message']);

const charCounterEl = ref();

const remainingCharacters = computed(() =>
    props.characterLimit ? props.characterLimit - props.message.length : undefined,
);

function handleMessageInput(message) {
    if (props.characterLimit === undefined) {
        emit('update:message', message);
        return;
    }

    const isOverLimit = message.length > props.characterLimit;

    if (isOverLimit) {
        charCounterEl.value?.shake();
    }

    emit('update:message', message);
}
</script>

<template>
    <form @submit.prevent v-bind="rootAttrs">
        <TextAreaInput
            @focus="messageFocused = true"
            @blur="messageFocused = false"
            name="message"
            inputClass="border-none input--small min-h-[64px] shadow-[0_4px_30px_0px_rgba(195,195,250,0.3)] text-[max(1.125rem,16px)]"
            :errors="errors.message"
            :modelValue="message"
            @update:modelValue="handleMessageInput"
            placeholder="Your question"
            v-bind="messageInputAttrs"
        >
        </TextAreaInput>
        <CharacterCounter
            v-if="characterLimit !== unedfined"
            ref="charCounterEl"
            :message="message"
            :characterLimit="characterLimit"
        />

        <Transition name="reveal-down">
            <div v-show="withNameInput ? revealNameInput : true" class="mt-6 space-y-10">
                <div v-if="showNameField && withNameInput" class="grow">
                    <TextInput
                        @focus="nameFocused = true"
                        @blur="nameFocused = false"
                        class="rounded-xl shadow-[0_4px_30px_0px_rgba(195,195,250,0.3)]"
                        inputClass="border-none input--small text-[max(1.125rem,16px)]"
                        name="name"
                        :errors="errors.name"
                        :modelValue="name"
                        @update:modelValue="$event => emit('update:name', $event)"
                        placeholder="Your name (optional)"
                        v-bind="nameInputAttrs"
                    >
                    </TextInput>
                </div>
                <slot v-bind="{ remainingCharacters }"></slot>
            </div>
        </Transition>
    </form>
</template>
